import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import {
  MenuItem,
  Select,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  OutlinedInput,
  SelectChangeEvent,
  TextField,
  Button,
} from '@mui/material';
import { ExportButton } from 'react-admin';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ru } from 'date-fns/locale';
import { useHttpClient } from '../../../../hooks/useHttpClient';
import { API_URL } from '../../../../constants';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';

const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 16px 24px;
  border-radius: 8px;
`;

const styledButtonCSS = css`
  display: flex;
  background-color: #ffffff;
  padding: 16px 20px;
  border-radius: 8px;
  text-transform: none;
  height: 42px;
  color: #4f4f4f;
  font-size: 14px;
`;

const StyledExportButton = styled(ExportButton)`
  ${styledButtonCSS}
`;

const StyledButton = styled(Button)`
  border-color: #ffffff;

  &:hover {
    border-color: #eef3f7;
  }

  ${styledButtonCSS}
`;

const controlCSS = css`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    min-width: 150px;
    background-color: #ffffff;
    border-radius: 8px;
    margin: 0;
  }

  .MuiInputBase-root {
    width: 100%;
    & .MuiOutlinedInput-notchedOutline {
      border-color: #ffffff;
    }
    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: #ffffff;
    }
  }

  .MuiOutlinedInput-root {
    border-radius: 8px;
    background-color: #ffffff;
    padding-right: 8px;
    height: 42px;
    border-color: #ffffff;
  }

  .MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75); /* Уменьшение метки при фокусе */
  }

  .MuiSelect-icon {
    color: #4f4f4f; /* Цвет стрелки выпадающего списка */
  }
`;

const StyledFormControl = styled(FormControl)`
  ${controlCSS}
`;

const StyledCheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 150px;
  background-color: #ffffff; /* Белый фон */
  border-radius: 8px; /* Скругленные углы */
  height: 42px; /* Фиксированная высота */
  padding: 16px 20px; /* Внутренний отступ */
  gap: 10px;
  .MuiButtonBase-root {
    padding: 0;
    &.MuiSvgIcon-root {
      width: 18px;
    }
  }
`;

const StyledDatePicker = styled(DatePicker<Date>)`
  margin: 0;
  ${controlCSS}

  .MuiButtonBase-root {
    margin-right: 0;
  }
`;

const StyledCheckboxLabel = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: #4f4f4f;
`;

export const Filters = ({ onApply, onReset }: { onApply: (filters: any) => void; onReset: () => void }) => {
  const { data } = useHttpClient<{ availableCitiesFilter: string[] }>(API_URL.getWarranties);
  const [selectedCities, setSelectedCities] = useState<string[]>([]);
  const [warrantyStatus, setWarrantyStatus] = useState('');
  const [isTelegramId, setTelegramId] = useState<boolean>(false);
  const [withDeleted, setWithDeleted] = useState<boolean>(false);
  const [purchaseDate, setPurchaseDate] = useState<Date | null>(null);
  const [russianCities, setRussianCities] = useState<string[]>([]);

  useEffect(() => {
    if (data) {
      setRussianCities(data.availableCitiesFilter);
    }
  }, [data]);

  // Обновляем фильтры после каждого изменения
  useEffect(() => {
    const filters = {
      ...(warrantyStatus.length ? { warrantyStatus } : { warrantyStatus: undefined }),
      ...(isTelegramId ? { isTelegramId: true } : { isTelegramId: undefined }),
      ...(withDeleted ? { isDeleted: true } : { isDeleted: false }),
      ...(purchaseDate ? { purchaseDate } : { purchaseDate: undefined }),
      ...(selectedCities.length ? { cities: selectedCities } : { cities: undefined }),
    };

    const timer = setTimeout(() => {
      onApply(filters);
    }, 300);

    return () => clearTimeout(timer);
  }, [selectedCities, warrantyStatus, isTelegramId, purchaseDate, withDeleted]);

  const handleCityChange = (event: SelectChangeEvent<string[]>) => {
    setSelectedCities(event.target.value as string[]);
  };

  const handleWarrantyChange = (event: any) => {
    setWarrantyStatus(event.target.value as string);
  };

  const handleTelegramIdChange = () => {
    setTelegramId(!isTelegramId);
  };

  const handleWithDeletedChange = () => {
    setWithDeleted(!withDeleted);
  };

  const handleResetFilters = () => {
    setSelectedCities([]);
    setWarrantyStatus('');
    setTelegramId(false);
    setPurchaseDate(null);
    setWithDeleted(false);
    onReset();
  };

  const handleDateChange = (newDate: Date | null) => {
    if (newDate) {
      // Преобразуем дату в UTC (устанавливаем время на 00:00:00 в UTC)
      const utcDate = new Date(Date.UTC(newDate.getUTCFullYear(), newDate.getUTCMonth(), newDate.getUTCDate()));
      setPurchaseDate(utcDate);
    } else {
      setPurchaseDate(null);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ru}>
      <FiltersWrapper>
        {/* Telegram ID Checkbox */}
        <StyledCheckboxWrapper>
          <Checkbox
            checked={isTelegramId}
            onChange={handleTelegramIdChange}
            style={{
              color: isTelegramId ? '#2195f1' : '#4f4f4f', // Цвет активного/неактивного состояния
            }}
          />
          <StyledCheckboxLabel>Telegram ID</StyledCheckboxLabel>
        </StyledCheckboxWrapper>
        <StyledFormControl variant="outlined">
          <InputLabel>Статус гарантии</InputLabel>
          <Select
            value={warrantyStatus}
            onChange={handleWarrantyChange}
            input={<OutlinedInput label="Статус гарантии" />}>
            <MenuItem value="active">Активна</MenuItem>
            <MenuItem value="inactive">Не активна</MenuItem>
          </Select>
        </StyledFormControl>
        <StyledFormControl variant="outlined">
          <InputLabel>Города</InputLabel>
          <Select
            multiple
            value={selectedCities}
            onChange={handleCityChange}
            input={<OutlinedInput label="Города" />}
            renderValue={selected => (selected as string[]).join(', ')}>
            {russianCities.map(city => (
              <MenuItem key={city} value={city}>
                <Checkbox checked={selectedCities.includes(city)} />
                <ListItemText primary={city} />
              </MenuItem>
            ))}
          </Select>
        </StyledFormControl>
        <StyledDatePicker
          label="Дата покупки"
          value={purchaseDate}
          onChange={handleDateChange}
          componentsProps={{
            actionBar: {
              actions: ['clear', 'cancel'], // Specify action buttons
            },
          }}
          inputFormat="dd.MM.yyyy"
          renderInput={params => <TextField {...params} variant="outlined" />}
        />
        <StyledCheckboxWrapper>
          <Checkbox
            checked={withDeleted}
            onChange={handleWithDeletedChange}
            style={{
              color: withDeleted ? '#2195f1' : '#4f4f4f', // Цвет активного/неактивного состояния
            }}
          />
          <StyledCheckboxLabel>Удаленные</StyledCheckboxLabel>
        </StyledCheckboxWrapper>
        <StyledButton variant="outlined" startIcon={<CleaningServicesIcon />} onClick={handleResetFilters}>
          Очистить
        </StyledButton>
        <StyledExportButton label={'Экспорт'} />
      </FiltersWrapper>
    </LocalizationProvider>
  );
};
