import { TopToolbar, useListContext } from 'react-admin';
import styled from 'styled-components';
import { Filters } from './filters';
import { CustomTabs } from './custom-tabs';
import React, { useCallback, useState } from 'react';

const StyledTopToolbar = styled(TopToolbar)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 27px;
  padding-bottom: 37px;
`;

export const CustomListActions = () => {
  const { setFilters } = useListContext();
  const [currentFilters, setCurrentFilters] = useState<object>({});
  const [tabValue, setTabValue] = React.useState<number>(0);

  const handleApplyFilters = async filters => {
    try {
      setCurrentFilters({ ...currentFilters, ...filters });
      setFilters(
        { ...currentFilters, ...filters },
        { isTelegramId: true, isReviewed: true, warrantyStatus: true, purchaseDate: true, isDeleted: true },
      );
    } catch (error) {
      console.error('Error applying filters:', error);
    }
  };

  const handleResetFilters = useCallback(() => {
    setCurrentFilters({});
    setFilters({}, {});
    setTabValue(0);
  }, []);

  const handleTabChange = useCallback((value: number) => {
    setTabValue(value);
  }, []);

  return (
    <StyledTopToolbar>
      <CustomTabs onApply={handleApplyFilters} value={tabValue} onChange={handleTabChange} />
      <Filters onApply={handleApplyFilters} onReset={handleResetFilters} />
    </StyledTopToolbar>
  );
};
