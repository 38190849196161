export enum ResourseNames {
  hardwares = 'hardwares',
  users = 'users',
  cartridges = 'cartridges',
  models = 'models',
  admins = 'admins',
  devices = 'devices',
  firmwares = 'firmwares',
  warranties = 'warranties',
}

export interface Query<T> {
  isLoading?: boolean;
  data?: T extends Record<string, unknown> ? T : any;
  error?: any;
}

export enum Roles {
  Admin = 'admin',
  SuperAdmin = 'superadmin',
  Marketing = 'marketing',
}

export enum Permissions {
  //Admins
  ReadAdmins = 'read_admins',
  // Users
  ReadUsers = 'read_users',
  CreateUsers = 'create_users',
  EditUsers = 'edit_users',
  DeleteUsers = 'delete_users',

  // Devices
  ReadDevices = 'read_devices',
  CreateDevices = 'create_devices',
  EditDevices = 'edit_devices',
  DeleteDevices = 'delete_devices',

  // Firmwares
  ReadFirmwares = 'read_firmwares',
  CreateFirmwares = 'create_firmwares',
  EditFirmwares = 'edit_firmwares',
  DeleteFirmwares = 'delete_firmwares',

  // Hardwares
  ReadHardwares = 'read_hardwares',
  CreateHardwares = 'create_hardwares',
  EditHardwares = 'edit_hardwares',
  DeleteHardwares = 'delete_hardwares',

  // Cartridges
  ReadCartridges = 'read_cartridges',
  CreateCartridges = 'create_cartridges',
  EditCartridges = 'edit_cartridges',
  DeleteCartridges = 'delete_cartridges',

  // Models
  ReadModels = 'read_models',
  CreateModels = 'create_models',
  EditModels = 'edit_models',
  DeleteModels = 'delete_models',

  // OTA devices
  ReadOtaDevices = 'read_ota_devices',

  //Warranties
  ReadWarranties = 'read_warranties',
  EditWarranties = 'edit_warranties',
  DeleteWarranties = 'delete_warranties',

  //Feature Toggles
  ReadFeatureToggles = 'read_feature_toggles',
  EditFeatureToggles = 'edit_feature_toggles',

  // Settings
  ManageSettings = 'manage_settings',
}

export const rolePermissions: { [key in Roles]: Permissions[] } = {
  [Roles.Admin]: [
    //Admins
    Permissions.ReadAdmins,
    // Users
    Permissions.ReadUsers,
    Permissions.CreateUsers,
    Permissions.EditUsers,
    Permissions.DeleteUsers,

    // Devices
    Permissions.ReadDevices,
    Permissions.CreateDevices,
    Permissions.EditDevices,
    Permissions.DeleteDevices,

    // Firmwares
    Permissions.ReadFirmwares,
    Permissions.CreateFirmwares,
    Permissions.EditFirmwares,
    Permissions.DeleteFirmwares,

    // Hardwares
    Permissions.ReadHardwares,
    Permissions.CreateHardwares,
    Permissions.EditHardwares,
    Permissions.DeleteHardwares,

    // Cartridges
    Permissions.ReadCartridges,
    Permissions.CreateCartridges,
    Permissions.EditCartridges,
    Permissions.DeleteCartridges,

    // Models
    Permissions.ReadModels,
    Permissions.CreateModels,
    Permissions.EditModels,
    Permissions.DeleteModels,

    //Ota Devices
    Permissions.ReadOtaDevices,

    //Feature Toggles
    Permissions.ReadFeatureToggles,
    Permissions.EditFeatureToggles,

    // Settings
    Permissions.ManageSettings,
  ],
  [Roles.SuperAdmin]: [
    // Users
    Permissions.ReadUsers,
    Permissions.CreateUsers,
    Permissions.EditUsers,
    Permissions.DeleteUsers,

    // Devices
    Permissions.ReadDevices,
    Permissions.CreateDevices,
    Permissions.EditDevices,
    Permissions.DeleteDevices,

    // Firmwares
    Permissions.ReadFirmwares,
    Permissions.CreateFirmwares,
    Permissions.EditFirmwares,
    Permissions.DeleteFirmwares,

    // Hardwares
    Permissions.ReadHardwares,
    Permissions.CreateHardwares,
    Permissions.EditHardwares,
    Permissions.DeleteHardwares,

    // Cartridges
    Permissions.ReadCartridges,
    Permissions.CreateCartridges,
    Permissions.EditCartridges,
    Permissions.DeleteCartridges,

    // Models
    Permissions.ReadModels,
    Permissions.CreateModels,
    Permissions.EditModels,
    Permissions.DeleteModels,

    //Ota Devices
    Permissions.ReadOtaDevices,
    Permissions.ReadWarranties,

    //Feature Toggles
    Permissions.ReadFeatureToggles,
    Permissions.EditFeatureToggles,

    // Settings
    Permissions.ManageSettings,
  ],
  [Roles.Marketing]: [
    Permissions.ReadUsers,
    Permissions.ReadDevices,
    Permissions.ReadCartridges,
    Permissions.ReadFirmwares,
    Permissions.ReadHardwares,
    Permissions.ReadModels,
    Permissions.ReadOtaDevices,
  ],
};

export enum StorageKeys {
  token = 'token',
  permissions = 'permissions',
}
